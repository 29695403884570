import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_NEW_PROJECT_MODAL,
    VIEW_ARTISAN_OR_PROJECT_MODAL,
} from "../../types/constants";
import { MdAdd } from "react-icons/md";

interface UserObj {
    id: string;
    name: string;
    createdAt: string;
}

const Projects = () => {

    const ALL_USERS_COLUMNS = [
        // {
        //     Header: "Project ID",
        //     accessor: "project_id",
        // },
        {
            Header: "Project Name",
            accessor: "project_name",
        },
        {
            Header: "Person Name",
            accessor: "project_contact_details.person_name",
        },
        {
            Header: "Person Contact",
            accessor: "project_contact_details.person_phone",
        },
        {
            Header: "Project Location",
            accessor: "project_location",
        },
        {
            Header: "Project Type",
            accessor: "project_type",
        },
        {
            Header: "Created At",
            accessor: "created_at",
        },
        {
            Header: "Artisans list",
            accessor: "project_id",
            Cell: ({ value, row }: any) => {
                return (
                    <button
                        className="bg-green-600 text-white px-2 py-3"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: VIEW_ARTISAN_OR_PROJECT_MODAL,
                                    modalTitle: "View Artisans",
                                    modalProps: {
                                        isArtisanList: true,
                                        id: value,
                                    },
                                })
                            );
                        }}
                    >
                        <span className="mr-1">View List</span>
                    </button>
                );
            },
        },
        {
            Header: "Update",
            accessor: "updated_at",
            Cell: ({ value, row }: any) => {
                return (
                    <button
                        className="bg-green-600 text-white px-2 py-3"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_NEW_PROJECT_MODAL,
                                    modalTitle: "Add Project",
                                    modalProps: {
                                        isUpdate: true,
                                        data: row.original,
                                    },
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Update</span>
                    </button>
                );
            },
        },
        // {
        //   Header: "Address",
        //   accessor: "address",
        // },
    ];

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [, setSelectedRows] = useState([]);

    let component = null;

    const getAllProjectsDetails = async ({ queryKey }: any) => {
        const { data } = await axiosInstance.get(`/project/all`);

        return data?.data;
    };

    const { data, error, isLoading } = useQuery<
        UserObj[] | undefined,
        any,
        UserObj[],
        any[]
    >(
        ["getAllProjectsDetails", pageIndex, cPageSize, cSortBy, desc, q],
        getAllProjectsDetails
    );

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1} // do calculation here
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => {}}
                    />
                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_NEW_PROJECT_MODAL,
                                    modalTitle: "Add Project",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>

            {component !== null && component}
        </div>
    );
};

export default Projects;

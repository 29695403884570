import { Option } from ".";

// toast types
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const INFO = "INFO";
export const WARNING = "WARNING";
export const DEFAULT = "DEFAULT";

// modal types
export const ADD_CATEGORY_MODAL = "ADD_CATEGORY_MODAL";
export const EDIT_PRODUCT_MODAL = "EDIT_PRODUCT_MODAL";
export const ADD_CROP_MODAL = "ADD_CROP_MODAL";
export const ADD_SEED_TYPE_MODAL = "ADD_SEED_TYPE_MODAL";
export const ADD_SEED_VARIETY_MODAL = "ADD_SEED_VARIETY_MODAL"
export const ADD_CROP_VARIETY_MODAL = "ADD_CROP_VARIETY_MODAL";
export const ADD_FERTILISER_MODAL = "ADD_FERTILISER_MODAL";
export const ADD_FERTILISER_COMBINATION_MODAL = "ADD_FERTILISER_COMBINATION_MODAL";
export const ADD_ICAR_MODAL = "ADD_ICAR_MODAL";
export const EDIT_ICAR_MODAL = "EDIT_ICAR_MODAL"
export const VIEW_PREPARATION_MODAL = "VIEW_PREPARATION_MODAL"
export const VIEW_PYROLYSIS_MODAL = "VIEW_PYROLYSIS_MODAL"
export const VIEW_BIOCHAR_MODAL = "VIEW_BIOCHAR_MODAL"
export const VIEW_SEQUESTRATION_MODAL = "VIEW_SEQUESTRATION_MODAL"
export const ADD_BIOMASS_COMPOSE_MODAL = "ADD_BIOMASS_COMPOSE_MODAL"
export const ADD_PYROLYSIS_KILN_MODAL = "ADD_PYROLYSIS_KILN_MODAL"
export const ADD_SOIL_PIT_MODAL = "ADD_SOIL_PIT_MODAL"
export const ADD_MEASURING_VESSEL_MODAL = "ADD_MEASURING_VESSEL_MODAL"
export const ASSIGN_BIOMASS_MODAL = "ASSIGN_BIOMASS_MODAL"
export const ADD_NEW_PROJECT_MODAL = "ADD_NEW_PROJECT_MODAL"
export const VIEW_ARTISAN_OR_PROJECT_MODAL = "VIEW_ARTISAN_OR_PROJECT_MODAL"
export const APPROVE_ARTISAN_MODAL = "APPROVE_ARTISAN_MODAL"
export const VIEW_ARTISAN_BATCHES_DETAIL = "VIEW_ARTISAN_BATCHES_DETAIL"

// user roles
export const USER_ROLES = {
  ADMIN: "ADMIN",
  ACCOUNT: "ACCOUNT",
  SALES: "SALES",
  FINANCE: "FINANCE",
  MARKETING: "MARKETING",
};

// user profile
export const USER_ROLES_CREATION = {
  ACCOUNTS: "ACCOUNTS",
  SALES: "SALES",
  PURCHASE: "PURCHASE",
  MARKETING: "MARKETING",
};

// Default Page sizes
export const DEFAULT_PAGE_SIZES = [10, 20, 50, 100];

// states
export const STATES: Option[] = [
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  {
    label: "Andhra Pradesh",
    value: "Andhra Pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
  },
  {
    value: "Assam",
    label: "Assam",
  },
  {
    value: "Bihar",
    label: "Bihar",
  },
  {
    value: "Chandigarh",
    label: "Chandigarh",
  },
  {
    value: "Chhattisgarh",
    label: "Chhattisgarh",
  },
  {
    value: "Dadra and Nagar Haveli",
    label: "Dadra and Nagar Haveli",
  },
  {
    value: "Daman and Diu",
    label: "Daman and Diu",
  },
  {
    value: "Delhi",
    label: "Delhi",
  },
  {
    value: "Goa",
    label: "Goa",
  },
  {
    value: "Gujarat",
    label: "Gujarat",
  },
  {
    value: "Haryana",
    label: "Haryana",
  },
  {
    value: "Himachal Pradesh",
    label: "Himachal Pradesh",
  },
  {
    value: "Jammu and Kashmir",
    label: "Jammu and Kashmir",
  },
  {
    value: "Jharkhand",
    label: "Jharkhand",
  },
  {
    value: "Karnataka",
    label: "Karnataka",
  },
  {
    value: "Kerala",
    label: "Kerala",
  },
  {
    value: "Ladakh",
    label: "Ladakh",
  },
  {
    value: "Lakshadweep",
    label: "Lakshadweep",
  },
  {
    value: "Madhya Pradesh",
    label: "Madhya Pradesh",
  },
  {
    value: "Maharashtra",
    label: "Maharashtra",
  },
  {
    value: "Manipur",
    label: "Manipur",
  },
  {
    value: "Meghalaya",
    label: "Meghalaya",
  },
  {
    value: "Mizoram",
    label: "Mizoram",
  },
  {
    value: "Nagaland",
    label: "Nagaland",
  },
  {
    value: "Odisha",
    label: "Odisha",
  },
  {
    value: "Puducherry",
    label: "Puducherry",
  },
  {
    value: "Punjab",
    label: "Punjab",
  },
  {
    value: "Rajasthan",
    label: "Rajasthan",
  },
  {
    value: "Sikkim",
    label: "Sikkim",
  },
  {
    value: "Tamil Nadu",
    label: "Tamil Nadu",
  },
  {
    value: "Telangana",
    label: "Telangana",
  },
  {
    value: "Tripura",
    label: "Tripura",
  },
  {
    value: "Uttar Pradesh",
    label: "Uttar Pradesh",
  },
  {
    value: "Uttarakhand",
    label: "Uttarakhand",
  },
  {
    value: "West Bengal",
    label: "West Bengal",
  },
];

import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { ADD_BIOMASS_COMPOSE_MODAL } from "../../types/constants";
import { MdAdd } from "react-icons/md";

interface UserObj {
    id: string;
    name: string;
    createdAt: string;
}

const BiomassComposition = () => {
    const ALL_USERS_COLUMNS = [
        {
            Header: "Biomass Id",
            accessor: "biomass_composition_id",
        },
        {
            Header: "Biomass Name",
            accessor: "biomass_name",
        },
        {
            Header: "Biomass Blend Details",
            accessor: "biomass_blend",
            Cell: ({ value }: any) => {
                return (
                    <div className="flex flex-col gap-2 justify-center items-center">
                        {Object.entries(value).map(([key, value]: any) => {
                            return (
                                <div className="flex flex-row min-w-[160px] max-w-[300px]">
                                    <p className="flex flex-1 justify-start">
                                        {key}:
                                    </p>
                                    <p className="flex flex-1 justify-end">
                                        {value}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            Header: "Avg diamter",
            accessor: "average_biomass_diameter",
        },
        {
            Header: "Avg length",
            accessor: "average_biomass_length",
        },
        {
            Header: "Biomass Img",
            accessor: "biomass_photo",
            Cell: ({ value }: any) => {
                return (
                    <a
                        href={value}
                        className="text-green-400"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Open Image
                    </a>
                );
            },
        },
        {
            Header: "Biomass Img",
            accessor: "moisture_meter_photo",
            Cell: ({ value }: any) => {
                return (
                    <>
                        {value ? (
                            <a
                                href={value}
                                className="text-green-400"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Open Image
                            </a>
                        ) : (
                            "No Image"
                        )}
                    </>
                );
            },
        },
    ];

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [, setSelectedRows] = useState([]);

    let component = null;

    const getAllBiomassComposition = async ({ queryKey }: any) => {
        const { data } = await axiosInstance.get(`/biomass_composition/all`);

        return data?.data;
    };

    const { data, error, isLoading } = useQuery<
        UserObj[] | undefined,
        any,
        UserObj[],
        any[]
    >(
        ["getAllBiomassComposition", pageIndex, cPageSize, cSortBy, desc, q],
        getAllBiomassComposition
    );

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1} // do calculation here
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => {}}
                    />
                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_BIOMASS_COMPOSE_MODAL,
                                    modalTitle: "Add Biomass Config",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>

            {component !== null && component}
        </div>
    );
};

export default BiomassComposition;

import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { uploadImage } from "../../http/uploadImage";
import AddNewFields from "../AddNewFields";
import { MdCancel } from "react-icons/md";

interface FormValues {
    biomass_name: string;
    cotton_stock: string;
    rice_straw: string;
    average_biomass_diameter: string;
    average_biomass_length: string;
}

const AddBiomassComposeModal = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [new_fields, setNewFields] = useState<
        { title: string; value: number }[]
    >([]);
    const [isNewField, setIsNewField] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [img, setImg] = useState<Object | null>(null);
    const [moistureMeterImg, setMoistureMeterImg] = useState<Object | null>(
        null
    );
    const initialValues: FormValues = {
        biomass_name: "",
        cotton_stock: "",
        rice_straw: "",
        average_biomass_diameter: "",
        average_biomass_length: "",
    };

    const validationSchema = Yup.object({
        biomass_name: Yup.string().required("Biomass Name is Required"),
        cotton_stock: Yup.string().required("Cotton Stock is Required"),
        rice_straw: Yup.string().required("Rice Straw is Required"),
        average_biomass_diameter: Yup.string().required(
            "Avg Biomass Diameter is Required"
        ),
        average_biomass_length: Yup.string().required(
            "Avg Biomass Length is Required"
        ),
    });

    const handleSubmit = async (biomass_compose: FormValues) => {
        if (!img) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "Image is required",
                })
            );
            return;
        }
        if (!moistureMeterImg) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "Moisture Meter Image is required",
                })
            );
            return;
        }
        setIsSubmitting(true);
        try {
            const imgRes = await uploadImage(img);
            const moistureMeterImgRes = await uploadImage(moistureMeterImg);
            if (!imgRes.data.publicUrl || !moistureMeterImgRes.data.publicUrl) {
                throw new Error("Image upload failed");
            }
            if (imgRes.data.publicUrl) {
                const compostObj = new_fields.reduce((acc: any, item) => {
                    acc[item.title] = item.value;
                    return acc;
                }, {});
                const obj = {
                    biomass_name: biomass_compose.biomass_name,
                    biomass_blend: {
                        cotton_stock: biomass_compose.cotton_stock,
                        rice_straw: biomass_compose.rice_straw,
                        ...compostObj,
                    },
                    average_biomass_diameter:
                        biomass_compose.average_biomass_diameter,
                    average_biomass_length:
                        biomass_compose.average_biomass_length,
                    biomass_photo: imgRes.data.publicUrl,
                    moisture_meter_photo: moistureMeterImgRes.data.publicUrl,
                };
                const result = await axiosInstance.post(
                    "/biomass_composition/create",
                    obj
                );
                if (result) {
                    console.log(result);
                    setIsSubmitting(false);
                    queryClient.invalidateQueries(["getAllBiomassComposition"]);
                    dispatch(
                        addToast({
                            kind: SUCCESS,
                            msg: "Biomass Config Added Successfully!",
                        })
                    );
                    dispatch(hideModal());
                }
            }
        } catch (error: any) {
            setIsSubmitting(false);

            if (error.response) {
                const response = error.response;
                const { msg } = response.data;

                switch (response.status) {
                    // bad request or invalid format or unauthorized
                    case 400:
                    case 500:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: msg,
                            })
                        );
                        break;
                    default:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: "Oops, something went wrong",
                            })
                        );
                        break;
                }
            } else if (error.request) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong",
                    })
                );
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: `Error: ${error.message}`,
                    })
                );
            }
        }
        setIsSubmitting(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form
                className="px-6 py-4 mt-2"
                style={{
                    minWidth: "360px",
                    maxWidth: "760px",
                }}
            >
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Biomass name"
                        id="biomass_name"
                        name="biomass_name"
                        type="text"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Cotton stock"
                        id="cotton_stock"
                        name="cotton_stock"
                        type="number"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Rice straw"
                        id="rice_straw"
                        name="rice_straw"
                        type="number"
                    />
                </div>
                {new_fields.map((field, index) => (
                    <div
                        key={index}
                        className="flex item-center gap-x-6 relative"
                    >
                        <MdCancel
                            size={20}
                            className="absolute right-0 text-primary cursor-pointer"
                            onClick={() => {
                                setNewFields(
                                    new_fields.filter(
                                        (item) => item.title !== field.title
                                    )
                                );
                            }}
                        />
                        <Input
                            label={field.title}
                            id={field.title}
                            name={field.title}
                            type="text"
                            value={field.value}
                            readOnly
                        />
                    </div>
                ))}
                <div className="flex justify-center items-center mb-4">
                    {!isNewField ? (
                        <button
                            onClick={() => {
                                setIsNewField(true);
                            }}
                            className="my-2 border-2 text-white bg-primary p-1 w-full"
                        >
                            Add New Field{" "}
                        </button>
                    ) : (
                        <AddNewFields
                            handleAdd={(title, value) => {
                                const fieldExists = new_fields.find(
                                    (field) =>
                                        field.title.toLowerCase() ===
                                        title.toLowerCase()
                                );
                                if (fieldExists) {
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: "Field already exists",
                                        })
                                    );
                                    return;
                                }
                                setNewFields([
                                    ...new_fields,
                                    { title, value: +value },
                                ]);
                                setIsNewField(false);
                            }}
                            handleCancel={() => {
                                setIsNewField(false);
                            }}
                        />
                    )}
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Average biomass diameter"
                        id="average_biomass_diameter"
                        name="average_biomass_diameter"
                        type="number"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Average biomass length"
                        id="average_biomass_length"
                        name="average_biomass_length"
                        type="number"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Biomass photo"
                        id="biomass_photo"
                        name="biomass_photo"
                        type="file"
                        accept=".jpg,.png,.jpeg,.wbep"
                        disabled={!!img}
                        onChange={(e) => {
                            if (e.target.files) {
                                setImg(e.target.files[0]);
                            }
                        }}
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Moisture meter photo"
                        id="moisture_meter_photo"
                        name="moisture_meter_photo"
                        type="file"
                        accept=".jpg,.png,.jpeg,.wbep"
                        disabled={!!moistureMeterImg}
                        onChange={(e) => {
                            if (e.target.files) {
                                setMoistureMeterImg(e.target.files[0]);
                            }
                        }}
                    />
                </div>
                <div className="buttons flex items-center w-full justify-center my-4">
                    <SubmitBtn
                        text="Save"
                        isSubmitting={isSubmitting}
                        classes="text-sm"
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default AddBiomassComposeModal;
